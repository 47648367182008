<div class="line-item-group flex gap-2">
  <div class="status-col flex flex-col items-center">
    <atom-icon
      class="cursor-pointer select-none text-xl text-primary mt-0.5"
      [ngClass]="iconClasses"
      (click)="onIsOpenedChange()"
      [iconId]="isOpened ? 'atom:core:arrow_down' : 'atom:core:arrow_forward'"
    >
    </atom-icon>

    @if (isOpened) {
      <div
        class="line mt-2 border-solid border-0 border-l-[2px] border-primary h-full"
      ></div>
    }
  </div>
  <div class="main-col flex-1">
    <ng-content select=".ev-accordion-header"></ng-content>
    <div #contentRef class="content-container" [class.hidden]="!isOpened">
      <ng-content select=".ev-accordion-content"></ng-content>
    </div>
  </div>
</div>
