<ev-modal-dialog
  data-cy="prompt-dialog"
  [$title]="title"
  [$subtitle]="subtitle"
  (closeDialog)="onClose()"
  data-cy="prompt-dialog"
>
  <div class="content" [formGroup]="promptForm">
    <atoms-input
      #promptInputField
      [label]="label"
      [placeholder]="placeholder"
      atomsControl2
      formControlName="promptInput"
    ></atoms-input>
    @if (promptForm.invalid && promptForm.dirty) {
      <div class="ev-input-error">
        {{ errorMessage }}
      </div>
    }
    <div class="buttons-wrapper">
      <atom-button secondary (click)="onClose()">{{
        cancelButtonText
      }}</atom-button>
      <atom-button-submit (click)="onSubmit()" [disabled]="!promptForm.valid">
        {{ submitButtonText }}
      </atom-button-submit>
    </div>
  </div>
</ev-modal-dialog>
