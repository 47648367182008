<div
  class="notification grid grid-cols-[40px_1fr_40px] p-3 items-center"
  [ngClass]="[type]"
>
  @switch (type) {
    @case ("error") {
      <atom-icon iconId="atom:basf:close_circle"></atom-icon>
    }
    @case ("warning") {
      <atom-icon iconId="atom:core:attention"></atom-icon>
    }
    @case ("info") {
      <atom-icon iconId="atom:action:info"></atom-icon>
    }
    @case ("success") {
      <atom-icon iconId="atom:core:check_circle"></atom-icon>
    }
  }
  <div>
    @if (title) {
      <strong>{{ title }}</strong>
    }
    <ng-content></ng-content>
  </div>
  @if (closable) {
    <atom-icon
      class="close-button"
      iconId="atom:core:close"
      (click)="onClose()"
    ></atom-icon>
  }
</div>
