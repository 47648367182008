@if (label) {
  <div class="ev-input-label">{{ label }}</div>
}
<div
  class="time-range-selector flex gap-2 items-center flex-wrap"
  [formGroup]="formGroup"
>
  <div
    class="input-container w-[115px]"
    [ngClass]="{
      'ev-error-outline': formGroup.invalid,
    }"
  >
    <atoms-dropdown atomsControl2 formControlName="timeFrom">
      @for (timeOption of timeOptions; track $index) {
        <atoms-option [value]="timeOption">{{ timeOption }}</atoms-option>
      }
    </atoms-dropdown>
  </div>
  -
  <div
    class="input-container w-[115px]"
    [ngClass]="{
      'ev-error-outline': formGroup.invalid,
    }"
  >
    <atoms-dropdown atomsControl2 formControlName="timeTo">
      @for (timeOption of timeOptions; track $index) {
        <atoms-option [value]="timeOption">{{ timeOption }}</atoms-option>
      }
    </atoms-dropdown>
  </div>
</div>
