<div
  class="table-footer p-4 flex gap-2 justify-between items-center max-sm:flex-col max-sm:items-start"
>
  @if (totalItems > 0) {
    <div class="font-semibold">
      <span i18n>Showing</span> {{ visibleItemsStart }} - {{ visibleItemsEnd }}
      <span i18n>of</span> {{ totalItems }}
      <span i18n>items</span>
    </div>
  }
  @if (totalPages > 1) {
    <ev-paginator
      [numberOfPages]="totalPages"
      [activePageIndex]="activePageIndex"
      (activatePage)="activatePage.emit($event)"
    ></ev-paginator>
  }
</div>
