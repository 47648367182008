<div
  [ngClass]="isOpened ? 'primary' : 'secondary'"
  class="accordion-container p-4"
>
  <div
    class="header flex gap-2 justify-between content-between cursor-pointer items-center select-none"
    (click)="contentRef.children.length && onIsOpenedChange()"
  >
    <div class="header grow">
      <ng-content select=".accordion-header"></ng-content>
    </div>
    @if (contentRef.children.length) {
      <atom-icon
        class="shrink-0"
        [iconId]="isOpened ? 'atom:core:arrow_up' : 'atom:core:arrow_down'"
      ></atom-icon>
    }
  </div>
  <div #closedContentRef [class.hidden]="isOpened">
    <ng-content select=".closed-content"></ng-content>
  </div>

  <!-- Show chips in closed state -->
  <div #contentRef class="content-container" [class.hidden]="!isOpened">
    <!-- [ngClass]="visibleSelections.length > 0 ? 'pb-4' : ''" -->
    <ng-content select=".accordion-content"></ng-content>
  </div>
</div>
