<ev-modal-dialog
  [$title]="title"
  [$subtitle]="subtitle"
  [$hideCloseButton]="true"
  (closeDialog)="onClose()"
>
  <div class="buttons-wrapper flex justify-end">
    <atom-button (click)="onClose()" class="min-w-[80px]">
      {{ okButtonText }}
    </atom-button>
  </div>
</ev-modal-dialog>
